import React, { useEffect } from 'react'
import WhoWeAre from '../components/WhoWeAre/WhoWeAre'
import WhatWeDo from '../components/WhatWeDo/WhatWeDo'
import FeaturedWork from '../components/FeauturedWork/FeaturedWork'
import Testimonials from '../components/Testimonials/Testimonials'
import ContactUs from '../components/ContactUs/ContactUs'
import Hero from '../components/HeroSection/Hero'
import Reels from '../components/Reels/Reels'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the viewport
  }, []); 
  return (
    <div className=' '>
        <Hero/>
        <WhoWeAre/>
        <WhatWeDo/>
        <FeaturedWork/>
        <Testimonials/>
        <Reels/>
        <ContactUs/>
    </div>
  )
}

export default Home
import React, { useEffect } from 'react'
import { FaLinkedin } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css';
import AOS from 'aos';
const ContactUs = () => {
    useEffect(() => {
        AOS.init({
            // Global settings here...
        });
    }, []);
    return (
        <div id='contact' className='pt-28 pb-20 lg:pt-32 xl:pt-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-[#111111]'>
            <div

                className='w-full max-w-screen-min-screen'>
                <h1 className='text-primary-dark metro-semi uppercase mb-5' data-aos="fade-up" data-aos-duration="1000">/ Contact Us</h1>
                <h1 data-aos="fade-up" data-aos-duration="1000" className='text-white domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px]'>Get in touch, and let's create something great together. Let's turn your idea into an even better product.</h1>
                <hr className='w-[4rem] mt-44 block h-[1px] bg-primary-dark border-primary-dark items-center my-4 b ' />
                <a
                    href='mailto:contact@qlab.work'
                    className='pb-3 border-b border-transparent text-[30px] md:text-[36px] text-white metro transition-all duration-300 hover:border-secondary-light whitespace-normal'
                    style={{ wordWrap: 'break-word' }}
                >
                    contact@qlab.work
                </a>

                <p className='text-[18px] mb-20 md:text-[20px] lg:text-[26px] xl:text-[30px] text-secondary-light'>+1 647 781 2408</p>


                <div className='grid grid-cols-1 lg:grid-cols-2 items-center'>
                    <div>
                        <h1 className='text-[14px] md:text-[15px] mb-3 lg:text-[16px] metro-semi text-primary-dark upercase' >/ Follow Us</h1>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/company/qlabs-work/?viewAsMember=true'> <FaLinkedin className='block  text-secondary-light text-4xl mb-3' /></a>

                        <Link to={'/terms-and-conditions'}> <p className='block  text-secondary-light text-xl ' >Terms & Conditions</p></Link>
                    </div>


                </div>
                <p className='block  text-secondary-light text-xl text-center mt-10'>Copyright @2024  All rights reserved by qlab.work</p>
            </div>
        </div>
    )
}

export default ContactUs
import React, { useState } from 'react';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { SlUser } from 'react-icons/sl';
import { LiaBuilding } from 'react-icons/lia';
import { AiTwotoneSetting} from 'react-icons/ai';
import { BiSolidUserCircle } from "react-icons/bi";
const Testimonials = () => {
    const [counterOn, setCounterOn] = useState(false);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1 ,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  const data = [
    {
      id: 1,
      name: 'Sarah Johnson',
      comment:
        "Working with Qlabs was a game-changer for our business! Their team delivered a top-notch web app that exceeded our expectations. From start to finish, the communication was excellent, and the final product was nothing short of impressive. Highly recommended!",

    },
    {
      id: 2,
      name: 'David Smith',
    
      comment:
        "I can't thank Qlabs enough for their exceptional work on our mobile app. Their attention to detail and commitment to quality were evident throughout the development process. The app they delivered has transformed our business and delighted our users. A fantastic team to work with!",
      
    },
    {
      id: 3,
      name: 'Emily Brown',
      
      comment:
        "Choosing Qlabs for our website redesign was one of the best decisions we made. Their designers created a stunning, user-friendly website that perfectly reflects our brand. The development team was efficient and responsive, ensuring a smooth launch. We couldn't be happier with the results!",

    },
    {
      id: 4,
      name: 'Michael Anderson',
      comment:"Qlabs is simply outstanding! Their expertise in web development is unmatched, and they go above and beyond to deliver exceptional results. Our project was completed on time and within budget, and the level of professionalism displayed by their team was commendable. Highly impressed!",
    },
    {
      id: 5,
      name: 'Jessica Taylor',
      comment:"I had the pleasure of working with Qlabs on a custom software solution, and I couldn't be more impressed. Their developers are incredibly talented, and they understood our requirements perfectly. The final product exceeded our expectations, and their ongoing support has been invaluable. Truly exceptional service!",
    },
    {
      id: 6,
      name: 'Daniel Martinez',
      comment:"From concept to execution, Qlabs exceeded all expectations. Their team took the time to understand our vision and delivered a website that perfectly captures our brand identity. The attention to detail and level of craftsmanship are second to none. I highly recommend their services to anyone looking for quality web development!",
    },
    {
      id: 7,
      name: 'Samantha White',
      comment:"I can't speak highly enough of Qlabs! Their team was a pleasure to work with, and they consistently went above and beyond to ensure our project's success. The level of expertise and professionalism they demonstrated throughout the process was truly impressive. We're thrilled with the results!",
    },
    {
      id: 8,
      name: 'Kevin Lee',
      comment:"Working with Qlabs was a breath of fresh air. Their team's dedication to our project's success was evident from day one, and they delivered a custom software solution that perfectly met our needs. The level of communication and transparency throughout the process was outstanding. Highly recommend!",
    },
    {
      id:9 ,
      name: 'Lauren Miller',
      comment:"Choosing Qlabs for our mobile app development was one of the best decisions we made. Their team's technical expertise and creative vision brought our app idea to life in ways we never imagined. The app they delivered has received rave reviews from our users, and we couldn't be happier with the outcome. Thank you!",
    },
    {
      id: 10,
      name: 'Thomas Wilson',
      comment:"I had the pleasure of working with Qlabs on a complex software project, and I'm incredibly impressed with the results. Their team's attention to detail, responsiveness, and dedication to our project's success were truly remarkable. The final product exceeded our expectations, and I wouldn't hesitate to recommend their services to others. Thank you for a job well done!",
    },
  ];

  const icons = [
    { icon: <SlUser className='p-5'/>, start: 50, end: 5,title:"Years of Experience" },
    { icon: <AiTwotoneSetting className='p-5' />, start: 0, end: 100,title:"Projects Completed" },
    { icon: <LiaBuilding className='p-5'/>, start: 0, end: 100 ,title:"Clients"},
    { icon: <LiaBuilding className='p-5'/>, start: 50, end: 15 ,title:"Team Members"},
    
  ];

  return (<>
    <div className='pb-28  lg:pb-32 xl:pb-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-white'>
      <div className='w-full max-w-screen-min-screen'>
        <h1
          data-aos='fade-up'
          data-aos-duration='1000'
          className='text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[36px] text-center'
        >
          What Clients Are Saying.
        </h1>
        <Slider {...settings}>
          {data.map((testimonial) => (
            <div key={testimonial.id} className='border-t'>
              <hr className='ml-9 mb-2 h-10 w-[2px] border' />
              <div className='flex flex-col md:flex-row gap-8'>
                <p
                  
                ><BiSolidUserCircle className='text-[#26bfb5]' size={78}/></p>
                <div>
                  <p className='text-[18px] metro text-secondary-light mb-10'>
                    {testimonial.comment}
                  </p>
                  <h1 className='text-[18px] metro-bold mb-10'>
                    {testimonial.name}
                  </h1>
                  {/* <h3 className='text-[14px] text-secondary-light mb-10'>
                    {testimonial.company} CEO
                  </h3> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        
      </div>
      
    </div>
    <div className='bg-center bg-cover w-full flex justify-center items-center' style={{
        backgroundImage: `url('https://preview.colorlib.com/theme/transcend/images/stats-bg.jpg.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} className='w-full'>
        <div className={`w-full bg-black bg-opacity-80 px-5 md:px-16 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center items-center   md:space-x-10 py-20`}>
          {icons.map((item, index) => (
            <div key={index} className=' flex flex-row justify-center  text-white items-center mb-6  sm:mb-0'>
              
              <div className='flex flex-col items-center justify-center'>
              <h1 className={`text-[84px] md:text-[90px] lg:text-[92px] border-b border-secondary-light`}>{counterOn && <CountUp start={item.start} end={item.end} duration={3} delay={0} />}+</h1>
              <h1 className={`mt-6 text-secondary-light text-[18px] metro '} `}>{item.title}</h1>
              </div>
            </div>
          ))}
        </div>
      </ScrollTrigger>
      </div>
    </>
  );
};

export default Testimonials;

import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import AOS from 'aos';

const data = [
    {
        id: 1,
        imgUrl: '/avatar1.png',
        name: 'Fillipe Guerreiro',
        title: 'Founder and Product Research Head',
        fade:'fade-right'
    },
    {
        id: 2,
        imgUrl: '/avatar.png',
        name: 'Sania Khan',
        title: 'Co-Founder and Backend Lead',
        fade:'fade-right'
    },
    {
        id: 3,
        imgUrl: '/avatar2.png',
        name: 'Hasnat Khan',
        title: 'Client Management and Marketing Expert',
        fade:'fade-left'
    },
    {
        id: 4,
        imgUrl: '/avatar3.png',
        name: 'Hamna Khan',
        title: 'Program And Operation Manager',
        fade:'fade-left'
    },
    {
        id: 5,
        imgUrl: '/avatar4.png',
        name: 'Abdullah Javed',
        title: 'Frontend Developer Junior Intern ',
        fade:'fade-right'
    },
    {
        id: 6,
        imgUrl: '/avatar1.png',
        name: 'Tabassum Ali',
        title: 'Backend Developer Junior Intern',
        fade:'fade-right'
    },
    {
        id: 7,
        imgUrl: '/avatar5.png',
        name: 'Ali Sher Khan',
        title: 'Frontend Developer Junior Intern',
        fade:'fade-left'
    },
    {
        id: 8,
        imgUrl: '/avatar4.png',
        name: 'Hashir Khan',
        title: 'Frontend Developer Junior Intern',
        fade:'fade-left'
    },
    {
        id: 9,
        imgUrl: '/avatar8.png',
        name: 'Hamna Zahid',
        title: 'Content Creation Manager',
        fade:'fade-right'
    },
    {
        id: 10,
        imgUrl: '/avatar12.png',
        name: 'Touseef Husnain',
        title: 'Cyber Security Expert',
        fade:'fade-right'
    },
    {
        id: 11,
        imgUrl: '/avatar13.png',
        name: 'Adeel ALi',
        title: 'Senior Frontend Team Lead',
        fade:'fade-left'
    },
    {
        id: 12,
        imgUrl: '/avatar9.png',
        name: 'Affaq Khan',
        title: 'Frontend Junior Developer',
        fade:'fade-left'
    },
    {
        id: 13,
        imgUrl: '/avatar10.png',
        name: 'Fatima Zahid',
        title: 'UI/UX Designer',
        fade:'fade-right'
    },
    {
        id: 14,
        imgUrl: '/avatar11.png',
        name: 'Saqib Shams',
        title: 'Mechatronics Developer ',
        fade:'fade-right'
    },
    {
        id: 15,
        imgUrl: '/avatar10.png',
        name: 'Sumiyya Abbasi',
        title: 'Backend Junior Intern',
        fade:'fade-left'
    },
    {
        id: 16,
        imgUrl: '/avatar3.png',
        name: 'Fatima Zohra',
        title: 'Frontend Junior Developer',
        fade:'fade-left'
    },
    {
        id: 17,
        imgUrl: '/avatar12.png',
        name: 'Usman Naveed',
        title: 'Frontend Team Lead',
        fade:'fade-right'
    },
    {
        id: 18,
        imgUrl: '/avatar4.png',
        name: 'Waqas Ahmed',
        title: 'UI/UX Designer Expert',
        fade:'fade-right'
    },
    {
        id: 19,
        imgUrl: '/avatar4.png',
        name: 'Ashraf Nasib',
        title: 'Frontend Developer',
        fade:'fade-left'
    },
    {
        id: 20,
        imgUrl: '/avatar13.png',
        name: 'Raja Habib Ahmed',
        title: 'Frontend Junior Developer',
        fade:'fade-left'
    },
]
const About = () => {
    useEffect(() => {
        AOS.init({
            // Global settings here...
        });
    }, []);
    return (
        <>
            <div>
                {/* our story */}

                <h1 data-aos="fade-up" data-aos-duration="1000" className='heading text-center'>Our Story</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para  text-center' >Our team of experts is constantly pushing the boundaries of technology to bring you the most advanced and user-friendly software on the market. Whether you're looking to streamline your operations, improve your customer experience, or simply stay ahead of the competition, we have the tools and expertise to help you achieve your goals. Browse our website to learn more about our products and services, and discover how we can help you succeed in today's connected world.</p>
                {/* our mission */}
                <h1 data-aos="fade-up" data-aos-duration="1000" className='heading text-center'>Our Mission</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para  text-center' >We are a software company that is determined to make a difference in the world. We know that technology had the power to solve problems and improve people's lives, but they also knew that it could be overwhelming and confusing for those who weren't tech-savvy.<br />
                    So, the company set out to create software that was not only powerful and efficient but also easy to use and understand. We work tirelessly to design intuitive interfaces and streamline processes that make technology accessible to everyone.</p>
                <p data-aos="fade-up" data-aos-duration="1000" className='para  text-center' >As we continue to develop software, the company has made sure to prioritize security and privacy. we know that trust was essential in any relationship, especially in the digital world.<br />
                    Through our dedication and commitment to excellence, our company quickly gained a reputation for providing top-notch software solutions that exceeded clients' expectations.</p>
                <p data-aos="fade-up" data-aos-duration="1000" className='para  text-center' >Today, Qlab continues to push the boundaries of what's possible with technology, always striving to make a positive impact. We invite you to join us on this journey to help you produce Superior products that will make your customers' lives better.</p>
            </div>

            {/* Our Team */}

            <h1 data-aos="fade-up" data-aos-duration="1000" className='heading text-center'>Our Team</h1>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                {data.map((data,id)=>(
                    <div key={id} data-aos={data.fade} data-aos-duration="1000" className='h-80 hover:scale-95 transition-all duration-300 cursor-pointer w-full relative bg-[#f1f1f1] rounded-xl flex flex-col p-4 pt-10 pb-6 justify-evenly items-center'>
                    <div className='fle flex-col justify-center items-center'>
                    <img src={data.imgUrl} alt={data.name} className='w-[161px] h-[161px] rounded-full mx-auto' />
                    <h1 className='text-2xl metro text-center' >{data.name}</h1>
                    </div>
                    <h1 className=' text-secondary-light text-base text-center leading-tight'>{data.title}</h1>
                    <p className='bg-[#26bfb5] p-1 w-10 h-10 rounded-full text-white font-semibold flex justify-center items-center absolute top-5 left-5 '>{data.id}</p>

                </div>
                ))}
            </div>
        </>
    )
}

export default About
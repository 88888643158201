import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa6';
import { IoIosClose } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
    
    const [isHovered, setIsHovered] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [scrollY, setScrollY] = useState(0); // Store scroll position in state
    const navigate = useNavigate()
    const [hoveredIndex, setHoveredIndex] = useState(null);
    useEffect(() => {
        const handleScroll = () => {
            const newScrollY = window.pageYOffset; // Get current scroll position
            setScrollY(newScrollY); // Update state with new scroll position
           

        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

      // Store scroll position in state
      const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          // Scroll to the section if it exists
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          // Redirect the user to the "Home" page
          navigate('/');
          
        }
        // Close the sidebar after scrolling
        setShowSidebar(false);
      };

    const handleSidebar = () => {
        setShowSidebar(!showSidebar)
    }
    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };
  
  return (
    <>
    <div className='flex  justify-between items-center h-fit py-8 w-full max-w-screen-3xl '>
                        <Link to={'/'}><img src='/favicon.png' alt='logo' className='w-20 h-16' /></Link>
                        <div
                            className='flex gap-4 cursor-pointer items-center  fixed right-10 md:right-16 z-20 max-screen:right-40 text-white'
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={handleSidebar}
                        >
                            <span className={`tracking-[2px] ${scrollY >= 50 ? 'opacity-0 transition-opacity duration-500' : ''} ${isHovered ? 'text-primary-dark' : 'hover:text-primary-dark transition-colors duration-500'}`}>MENU</span>
                            <FaBars className='text-[40px] bg-black p-1 ' />
                        </div>
                    </div>
 {/* sidebar */}
 <div
            className={`bg-black w-80 h-full fixed z-40 right-0 top-0 ease-in-out duration-500 p-6 ${showSidebar ? "-translate-x-0 " : "translate-x-full"
                }`}
        >
            <div className='flex justify-center items-center text-text gap-2 relative'>
                <IoIosClose onClick={() => setShowSidebar(!showSidebar)} className='absolute right-0 justify-self-end bg-black text-2xl text-white rounded-full ' />
            </div>
            <div className='flex font-bold justify-self-start gap-2 pl-10 pr-6 text-primary-dark'>
                Qlab
            </div>
            <ul className='flex flex-col pl-10 pr-6 mt-10'>
      <Link to={'/'}>
        <li
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={handleMouseLeave}
          className='text-base cursor-pointer text-white flex gap-6 border-t py-4 border-b border-secondary-light'
          onClick={() => scrollToSection('home')}
        >
          <span className={`${hoveredIndex === 1 ? 'text-primary-dark' : 'text-secondary-light'} `}>01</span>Home
        </li>
      </Link>
      <li
        onMouseEnter={() => handleMouseEnter(2)}
        onMouseLeave={handleMouseLeave}
        className='text-base cursor-pointer text-white flex gap-6 py-4 border-b border-secondary-light'
        onClick={() => scrollToSection('who-we-are')}
      >
        <span className={`${hoveredIndex === 2 ? 'text-primary-dark' : 'text-secondary-light'} `}>02</span>Who We Are
      </li>
      <Link to={'/about-us'}>
        <li 
        onMouseEnter={() => handleMouseEnter(3)}
        onMouseLeave={handleMouseLeave}
        className='text-base cursor-pointer text-white flex gap-6 py-4 border-b border-secondary-light' onClick={() => scrollToSection('who-we-are')}><span className={`${hoveredIndex === 3 ? 'text-primary-dark' : 'text-secondary-light'} `}>03</span>About Us</li>
      </Link>
      <li 
      onMouseEnter={() => handleMouseEnter(4)}
      onMouseLeave={handleMouseLeave}
      className='text-base cursor-pointer text-white flex gap-6 py-4 border-b border-secondary-light' onClick={() => scrollToSection('services')}><span className={`${hoveredIndex === 4 ? 'text-primary-dark' : 'text-secondary-light'} `}>04</span>Services</li>
      <li 
      
      onMouseEnter={() => handleMouseEnter(5)}
        onMouseLeave={handleMouseLeave}
        className='text-base cursor-pointer text-white flex gap-6 py-4 border-b border-secondary-light' onClick={() => scrollToSection('work')}><span className={`${hoveredIndex === 5 ? 'text-primary-dark' : 'text-secondary-light'} `}>05</span>Work</li>
      <li 
      onMouseEnter={() => handleMouseEnter(6)}
      onMouseLeave={handleMouseLeave}
      className='text-base cursor-pointer text-white flex gap-6 py-4 border-b border-secondary-light' onClick={() => scrollToSection('contact')}><span className={`${hoveredIndex === 6 ? 'text-primary-dark' : 'text-secondary-light'} `}>06</span>Contact</li>
      <Link to={'/terms-and-conditions'}>
        <li 
        onMouseEnter={() => handleMouseEnter(7)}
        onMouseLeave={handleMouseLeave}
        className='text-base cursor-pointer text-white flex gap-6 py-4 border-b border-secondary-light' ><span className={`${hoveredIndex === 7 ? 'text-primary-dark' : 'text-secondary-light'} `}>07</span>Terms & Conditions</li>
      </Link>
    </ul>
        </div>
                    </>
  )
}

export default Navbar
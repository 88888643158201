import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
const FeaturedWork = () => {
   
  useEffect(() => {
    AOS.init({
      // Global settings here...
    });
  }, []);
  const projects = [
    {
      imgSrc: "/air.png",
      
    },
    {
      imgSrc: "/ease.png",
      
    },
    {
      imgSrc: "/scan.png",
      height:'h-[125px]'
    },
    {
      imgSrc: "/betea.png",
      height:'h-[140px]'
      
    },{
      imgSrc: "/stem.png",
      
      height:'h-[65px]'
     
    },{
      imgSrc: "/sport.png",
      height:'h-[140px]'
     
    },
  ];
    return (
        <div id='work' className='py-28  lg:py-32 xl:py-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-white'>
            <div

                className='w-full max-w-screen-min-screen'>
                <h1 className='text-primary-dark metro-semi uppercase mb-5' data-aos="fade-up" data-aos-duration="1000">/ Featured Works</h1>
                <h1 data-aos="fade-up" data-aos-duration="1000" className='text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px]'>These are some of the recent companies our teams have worked with.</h1>
            
                <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10 '>
{projects.map((data,index)=>(
 <img key={index} src={data.imgSrc} className={`${data.height} md:h-auto mx-auto hover:scale-105 transition-all duration-500`} alt={index} />
))}
</div>
            </div>

            {/* <div data-aos="fade-up" data-aos-duration="1000" className="columns-2 lg:columns-2 w-full max-w-screen-min-screen">
      {projects.map((project, index) => (
        <div
          key={index}
          className={`relative  overflow-hidden ${isHovered === index ? 'hovered' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          
        >
          
          <img
            className={`w-full ${project.dynamicClass} hover:scale-110 transition-transform duration-300 object-center`}
            src={project.imgSrc}
            alt={project.title}
          />
          <p className={`${isHovered=== index ? "translate-x-0 " : "-translate-x-full"} transition-all duration-500 absolute z-20 top-5 left-0 flex items-center`}>
            <hr className="border-[#f1f1f1] w-7" />
            <Link to={project.path} target="_blank"><span className="px-2.5 py-2 bg-black text-white hover:bg-primary-dark transition-colors duration-500 font-medium metro">Project Link</span></Link>
          </p>
          <p className={`flex flex-col ${isHovered=== index ? "translate-y-0 opacity-100" : "-translate-y-[200%] opacity-0"} transition-all  duration-500 absolute z-20 bottom-5 leading-none left-7`}>
            <span className="text-[12px] metro-semi">{project.title}</span>
            <span className="text-[12px]">{project.type}</span>
          </p>
          <p className={`${isHovered=== index ? 'bg-white -inset-1 bg-opacity-40' : 'bg-transparent '} transition-all  duration-500 absolute top-0`}>
            <BsThreeDots className={` ${isHovered=== index? "opacity-100 scale-100":"scale-0 opacity-0"} top-1/2 transition-all  duration-500 absolute left-1/2 `} />
          </p>
        </div>
      ))}
    </div> */}
        </div>
    )
}

export default FeaturedWork
import React, { useEffect } from 'react'
import AOS from 'aos';
import { PiTelevision } from "react-icons/pi";
import { IoMdGlobe } from "react-icons/io";
import { BsBox } from "react-icons/bs";
import { HiOutlineWindow } from "react-icons/hi2";
import { FaRegCalendar } from "react-icons/fa";
import 'aos/dist/aos.css';
const WhatWeDo = () => {
    useEffect(() => {
        AOS.init({
          // Global settings here...
        });
      }, []);
  return (
    <div id='services' className='py-28 lg:py-32 xl:py-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-[#f1f1f1]'>
    <div

      className='w-full max-w-screen-min-screen'>
      <h1 className='text-primary-dark metro-semi uppercase mb-5' data-aos="fade-up" data-aos-duration="1000">/ Who We Are</h1>
      <h1 data-aos="fade-up" data-aos-duration="1000" className='text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px]'>We have everything you need to launch and grow a successful digital business.</h1>
      <p data-aos="fade-up" data-aos-duration="1000" className='text-[19px] lg:text-[22px] xl:text-[24px] text-secondary-light metro leading-relaxed  mb-6 lg:mb-8' >Driven by our passion for innovation and our unwavering commitment to excellence, we continuously push the boundaries of what's possible. Our focus on security and privacy ensures that our clients can trust us to safeguard their valuable data in an increasingly connected world.</p>
      </div>
       <div data-aos="fade-up" data-aos-duration="1000" className='grid w-full max-w-screen-min-screen grid-cols-2 md:grid-cols-3 justify-center gap-10 items-center'>
          <span className='flex items-cent flex-col text-[21px] font-semibold'><PiTelevision className='text-6xl text-[#26bfb5] mb-3' />Brand Identity</span>
          <span className='flex items-cent flex-col text-[21px] font-semibold'><IoMdGlobe className='text-6xl text-[#26bfb5] mb-3'/>Illustration</span>
          <span className='flex items-cent flex-col text-[21px] font-semibold'><IoMdGlobe className='text-6xl text-[#26bfb5] mb-3'/>Web Application Design</span>
          <span className='flex items-cent flex-col text-[21px] font-semibold'><BsBox className='text-6xl text-[#26bfb5] mb-3'/>Product Strategy</span>
          <span className='flex items-cent flex-col text-[21px] font-semibold'><HiOutlineWindow className='text-6xl text-[#26bfb5] mb-3'/>UI/UX Design</span>
          <span className='flex items-cent flex-col text-[21px] font-semibold'><FaRegCalendar className='text-6xl text-[#26bfb5] mb-3'/>Mobile App Developement</span>
         </div>
      </div>
  )
}

export default WhatWeDo

import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import NotFound from './components/NotFound/NotFound';
import AboutUs from './pages/AboutUs';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home/>}/> 
      <Route path='/about-us' element={<AboutUs/>}/> 
      <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/> 
      <Route path='*' element={<NotFound />} /> {/* Define the 404 route */}
    </Routes>
  </>
  );
}

export default App;

import React from 'react'

const Reels = () => {
  return (
    <div id='reels' className='py-28  lg:py-32 xl:py-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-white'>
    <div

        className='w-full max-w-screen-min-screen'>
        <h1 className='text-primary-dark metro-semi uppercase mb-5' data-aos="fade-up" data-aos-duration="1000">/ Reels</h1>
        <h1 data-aos="fade-up" data-aos-duration="1000" className='text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px]'>Design Beyond Limits: Unleashing Dynamic Creativity</h1>

        <video className='hidden md:block aspect-video' controls>
  <source src="/showreel.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video>
<video className=' block md:hidden aspect-auto' controls>
  <source src="/Tiktok.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video>
    </div>
    </div>
  )
}

export default Reels
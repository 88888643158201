import React, { useEffect } from 'react'
import Navbar from '../components/HeroSection/Navbar'
import ContactUs from '../components/ContactUs/ContactUs'
import 'aos/dist/aos.css';
import AOS from 'aos';
const TermsAndConditions = () => {
    useEffect(() => {
        AOS.init({
         
        });
      }, []);
      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the viewport
      }, []); 
    return (
        <>
        <div className='pb-28  lg:pb-32 xl:pb-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-white'>
            <div

                className='w-full max-w-screen-min-screen'>
                <Navbar />

                <h1 data-aos="fade-up" data-aos-duration="1000" className='heading'>Terms And Conditions</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para  ' >These terms and conditions outline the rules and regulations for the use of QLab.Work's services. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. If you disagree with any part of these terms, you must not use our services.
</p>


<h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>1. Copyright and Intellectual Property:</h1>

<p data-aos="fade-up" data-aos-duration="1000" className='para' >All content provided on QLab.Work, including text, graphics, logos, images, as well as the compilation thereof, and any software used on the site, is the property of QLab.Work or its suppliers and protected by copyright and other intellectual property laws. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the service, use of the service, or access to the service without express written permission by us.
</p>
                {/* Payment */}
                <h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>2. Provision of Services:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >QLab.Work specializes in developing innovative online solutions, including but not limited to website design and development, e-commerce, digital marketing, mobile app development, and more. Our services are aimed at businesses of all sizes seeking to enhance their digital presence and capabilities.
</p>

                {/* Timeline */}
                <h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>3. User Obligations:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >You agree to use our services only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of QLab.Work. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our services.
</p>

                   {/* Gurrantee */}
                   <h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>4. Disclaimer of Warranties:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >Our services are provided on an "as is" and "as available" basis. QLab.Work and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither QLab.Work, nor its suppliers and licensors, makes any warranty that our services will be error-free or that access thereto will be continuous or uninterrupted.
</p>

    {/* GMaintenance */}
    <h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>5. Limitation of Liability:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >In no event will QLab.Work, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data.
</p>


                 {/* Gurrantee */}
    <h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>6. Dispute Resolution:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >Any disputes arising out of or in connection with these terms shall be governed by and construed in accordance with the laws of the jurisdiction in which QLab.Work is based, without giving effect to any choice or conflict of law provision or rule.
</p>
<h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>7. Modification of Terms:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >QLab.Work reserves the right, at its sole discretion, to modify or replace any part of these Terms and Conditions at any time. It is your responsibility to check these Terms and Conditions periodically for changes. Your continued use of or access to our services following the posting of any changes to these Terms and Conditions constitutes acceptance of those changes.

</p>
<h1 data-aos="fade-up" data-aos-duration="1000" className='sub-heading'>8. Contact Information:</h1>
                <p data-aos="fade-up" data-aos-duration="1000" className='para' >For any questions about these Terms and Conditions, please contact us at contact@qlab.work or call us at +1647-781-2408
</p>
            </div>
        </div>
<ContactUs/>

        </>
    )
}

export default TermsAndConditions
import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import { GoArrowDown } from "react-icons/go";
import { FaLinkedin } from "react-icons/fa6";
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

const Hero = () => {
   
   
    
    const variants = {
        hidden: { y: '100%' },
        visible: { y: 0 },
    };
      // Store scroll position in state
      const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
           
        }
    };
    return (
        <div id='home' className=''>
            <ParallaxBanner
                layers={[{ image: '/herobg.jpg', speed: -10 }]}
                className="aspect-[2/1] flex justify-center h-screen min-h-[800px] relative bg-cent"
            >
                <div className=' flex justify-center flex-col items-center z-10 bg-black bg-opacity-70 -inset-0.5 absolute px-8 md:px-12 lg:px-16'>
                    {/* Hero */}
                    <Navbar  />
                    {/* hero */}
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ ease: 'easeOut', duration: 1 }}
                        className='h-full w-full flex max-w-[1100px]  pt-20'>
                        <div>
                            <h1 className='text-[40px] md:text-[65px] lg:text-[72px] xl:text-[86px] domin font-semibold leading-tight capitalize text-white'>not just another <br />digital agency.</h1>
                            <div className='flex flex-col lg:flex-row justify h-fit gap-2 mt-8'>
                                <hr className='w-[3rem] block h-[1px] bg-primary-dark border-primary-dark items-center my-4 b ' />
                                <h1 className='text-[22px] md:text-[24px] metro leading-tight text-[#FFFFFFCC]'>We specialize in providing innovative digital solutions<br /> to help your business grow and succeed in the<br /> ever-changing online landscape.</h1>
                            </div>
                        </div>


                    </motion.div>
                    {/* heroo bottom  */}
                    <div className='w-full max-w-screen-3xl flex justify-between pb-20'>
                        <div className='flex gap-4 items-center text-white list-none ' >
                            <li className='mr-8 '>Follow Us</li> 
                            <Link target="_blank" rel="noopener noreferrer" to='https://www.linkedin.com/company/qlabs-work/?viewAsMember=true'><li><span className='hidden sm:block'>LinkedIn</span> <FaLinkedin className='block sm:hidden text-white' /></li></Link>
                        </div>
                        <div className='flex gap-4 items-center cursor-pointer' onClick={() => scrollToSection('who-we-are')}>
                            <p className='uppercase text-white text-[11px] hidden lg:block'>Scroll Down</p>
                            <span className='w-12 h-12 flex items-center justify-center bg-white rounded-full'>
                                <GoArrowDown />
                            </span>
                        </div>
                    </div>

                    {/* hero side links */}
                    <div className='hidden lg:flex flex-col  absolute z-10 right-0 top-1/2 -translate-y-1/2'>
                        <div onClick={() => scrollToSection('who-we-are')} className='bg-black cursor-pointer bg-opacity-20 py-6 hover:bg-opacity-50 transition-all duration-500 pl-6 pr-40'>
                            <h1 className='flex flex-col text-white leading-3' >
                                <span className='flex items-center gap-4 text-[13px] tracking-[2px]'>
                                    <hr className='w-1.5 h-[7px] border-primary-dark rounded-full bg-primary-dark' />
                                    ABOUT</span> <br />
                                <span className='capitalize text-[15px] ml-5 text-[#FFFFFF80]'>Who we are</span>
                            </h1>
                        </div>
                        <div onClick={() => scrollToSection('services')} className='bg-black cursor-pointer bg-opacity-20 py-6 hover:bg-opacity-50 transition-all duration-500 pl-6 pr-40'>
                            <h1 className='flex flex-col text-white leading-3' >
                                <span className='flex items-center gap-4 text-[13px] tracking-[2px]'>
                                    <hr className='w-1.5 h-[7px] border-primary-dark rounded-full bg-primary-dark' />
                                    SERVICES</span> <br />
                                <span className='capitalize text-[15px] ml-5 text-[#FFFFFF80]'>What we do</span>
                            </h1>
                        </div>
                        <div onClick={() => scrollToSection('reels')} className='bg-black cursor-pointer bg-opacity-20 py-6 hover:bg-opacity-50 transition-all duration-500 pl-6 pr-40'>
                            <h1 className='flex flex-col text-white leading-3' >
                                <span className='flex items-center gap-4 text-[13px] tracking-[2px]'>
                                    <hr className='w-1.5 h-[7px] border-primary-dark rounded-full bg-primary-dark' />
                                    SHOW REELS</span> <br />
                                <span className='capitalize text-[15px] ml-5 text-[#FFFFFF80]'>What we do</span>
                            </h1>
                        </div>
                        <div onClick={() => scrollToSection('contact')} className='bg-black cursor-pointer bg-opacity-20 py-6 hover:bg-opacity-50 transition-all duration-500 pl-6 pr-40'>
                            <h1 className='flex flex-col text-white leading-3' >
                                <span className='flex items-center gap-4 text-[13px] tracking-[2px]'>
                                    <hr className='w-1.5 h-[7px] border-primary-dark rounded-full bg-primary-dark' />
                                    CONTACT</span> <br />
                                <span className='capitalize text-[15px] ml-5 text-[#FFFFFF80]'>Get in touch</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </ParallaxBanner>
           
        </div>
    )
}

export default Hero
import React, { useEffect } from 'react'
import Navbar from '../components/HeroSection/Navbar'
import About from '../components/AboutUs/About'
import ContactUs from '../components/ContactUs/ContactUs'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the viewport
  }, []); 
  return (
    <>
    <div className='pb-28  lg:pb-32 xl:pb-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-white'>
            <div

                className='w-full max-w-screen-min-screen'>
                    <Navbar/>
                    <About/>

    </div>
    </div>
    <ContactUs/>
    </>
  )
}

export default AboutUs
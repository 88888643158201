import React from 'react'
import Navbar from '../HeroSection/Navbar'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className='pb-28 lg:pb-32 xl:pb-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-[#f1f1f1]'>
            <div

                className='w-full max-w-screen-min-screen'>
                <Navbar  />

                <h1 className='text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px] '>Page Not Found 404</h1>
                <Link to='/'> <h1 className='text-primary-dark metro-semi uppercase mb-5' >/ Go to Home Page</h1></Link>
            </div>
        </div>
    )
}

export default NotFound
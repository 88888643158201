import React, { useEffect } from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';
const data = [
  {
    id: 1,
    title: 'Define',
    desc: 'At QLAB, we believe in laying a solid foundation for every project. During the Define phase, our expert team works closely with you to understand your goals, requirements, and vision. Through in-depth discussions and meticulous planning, we ensure that we have a clear understanding of your project objectives before moving forward.'
  }, {
    id: 2,
    title: 'Design',
    desc: ' With creativity and precision, our Design phase transforms ideas into stunning visuals and intuitive user experiences. Our talented designers craft beautiful and functional designs tailored to your brand identity and audience. From wireframes to prototypes, we prioritize user-centric design principles to create engaging interfaces that captivate and delight users.'
  },
  {
    id: 3,
    title: 'Build',
    desc: 'In the Build phase, our skilled developers bring designs to life with clean code and robust development practices. Leveraging the latest technologies and frameworks, we develop custom solutions tailored to your unique requirements. With a focus on scalability, performance, and security, we ensure that your web apps, mobile apps, and websites are built to exceed expectations.'
  },
  {
    id: 4,
    title: 'Launch',
    desc: 'The Launch phase marks the culmination of our collaborative efforts as we unveil your project to the world. With meticulous attention to detail, we prepare for a seamless rollout, conducting thorough testing and quality assurance to ensure a flawless launch. Whether its deploying web apps, launching mobile apps, or publishing websites, we handle every aspect to ensure a successful introduction to your audience.'
  },
]

const data2 = [
  {
    id: 1,
    desc: 'Welcome to Qlab, where innovation meets simplicity. We are a dedicated team of technology enthusiasts on a mission to revolutionize the digital landscape. Our goal is simple: to provide advanced and user-friendly software solutions that empower businesses to thrive in today\'s fast-paced world.'
  },
  {
    id: 2,
    desc: 'At Qlab, we believe in the power of technology to solve real-world problems and enhance peoples lives. That\`s why we\'re committed to developing cutting-edge software that not only streamlines operations and improves customer experiences but also remains accessible to all, regardless of technical expertise.'
  },
  {
    id: 3,
    desc: 'Driven by our passion for innovation and our unwavering commitment to excellence, we continuously push the boundaries of what\'s possible. Our focus on security and privacy ensures that our clients can trust us to safeguard their valuable data in an increasingly connected world.'
  },
  {
    id: 4,
    desc: 'Join us on this journey as we continue to push the limits of technology, always striving to make a positive impact and deliver superior products that make a difference. Explore our website to learn more about our innovative solutions and how we can help you achieve your goals. Together, let\'s embrace the future with confidence and unlock endless possibilities with Qlab.'
  },
]
const WhoWeAre = () => {
  useEffect(() => {
    AOS.init({
      // Global settings here...
    });
  }, []);

  return (
    <div id='who-we-are' className='py-28 lg:py-32 xl:py-44 px-5 md:px-16 lg:px-20 flex flex-col justify-center items-center bg-white'>
      <div

        className='w-full max-w-screen-min-screen'>
        <h1 className='text-primary-dark metro-semi uppercase mb-5' data-aos="fade-up" data-aos-duration="1000">/ Who We Are</h1>
        <h1 data-aos="fade-up" data-aos-duration="1000" className='text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px]'>We are a group of design driven individuals passionate about creating beautiful UI designs.</h1>
        {data2.map((data2, id) => (
          <p data-aos="fade-up" data-aos-duration="1000" className='text-[19px] lg:text-[22px] xl:text-[24px] text-secondary-light metro leading-relaxed  mb-6 lg:mb-8' key={id}>{data2.desc}</p>
        ))}


      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 w-full max-w-screen-min-screen gap-6'>
        {data.map((data, id) => (
          <div data-aos="fade-up" data-aos-duration="1000" className=' flex flex-col' key={id}>
            <h1 className='text-[26px] mb-3 h-fit flex  items-center metro-semi'><span className='w-[45px] h-[45px] flex items-center justify-center rounded-full text-white bg-[#26bfb5] mr-3 '>{id + 1}</span>{data.title}</h1>
            <p className='text-[17px] text-secondary-light h-fit  border-l-2  pl-9 ml-5 metro'>{data.desc}</p>
          </div>
        ))}

      </div>
     
    </div>
  )
}

export default WhoWeAre